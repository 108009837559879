import { Action } from "../actionTypes";

const initState = {
    photos: [],
    videos: [],
    item: {},
    error: "",
};
const store = (state = initState, action: any) => {
    switch (action.type) {
        case Action.GET_IMAGES_INIT:
            return {
                ...state
            }
        case Action.GET_IMAGES_SUCCESS:
            return {
                ...state,
                photos: action?.payload
            }
        case Action.GET_IMAGES_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_VIDEOS_INIT:
                return {
                    ...state
                }
        case Action.GET_VIDEOS_SUCCESS:
                return {
                    ...state,
                    videos: action?.payload
                }
        case Action.GET_VIDEOS_FAIL:
                return {
                    ...state,
                    error: action?.payload
                }
        case Action.GET_BC_INIT:
                return {
                    ...state
                }
        case Action.GET_BC_SUCCESS:
                return {
                    ...state,
                    item: action?.payload
            }
        case Action.GET_BC_FAIL:
                return {
                    ...state,
                    error: action?.payload
            }
        case Action.GET_PC_INIT:
                return {
                    ...state
                }
        case Action.GET_PC_SUCCESS:
                return {
                    ...state,
                    item: action?.payload
            }
        case Action.GET_PC_FAIL:
                return {
                    ...state,
                    item: action?.payload
            }
        default:
            return state;
    }
}

export default store;
