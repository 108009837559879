/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from 'react'
import {Link, Outlet, useLocation} from 'react-router-dom'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {PageDataProvider} from './core'
import {reInitMenu, toAbsoluteUrl} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {useDispatch, useSelector} from 'react-redux'
import {getCard} from '../../redux/action/card'
import {Navbar} from './components/header/Navbar'
// import {RightToolbar} from '../partials/layout/RightToolbar'
// import {ScrollTop} from './components/scroll-top' //omitted due to eslint warning
// import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials' //omitted due to eslint warning
// import {Sidebar} from './components/sidebar' //omitted due to eslint warning
// import {HeaderWrapper} from './components/header' //omitted due to eslint warning

const Headerlauyout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const userdata = useSelector((state: any) => state) //this is causing a pretty big warning in the browser
  const userId = userdata?._id

  // const Allcard = useSelector((state: any) => state.Card?.UserCard) //omitted due to eslint warning
  // const [activeTab, setActiveTab] = React.useState(1) //omitted due to eslint warning
  // const changeTab = (tabNumber: number) => { setActiveTab(tabNumber) } //omitted due to eslint warning

  useEffect(() => {
    handleGetAllCard()
  }, [userId])

  const handleGetAllCard = () => {
    if (userId) {
      dispatch(getCard({userId}))
    }
  }

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <div className='d-flex justify-content-between shadow p-4'>
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 gap-3'>
              <Link to='/dashboard' className=''>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/Dashlogo.png')} />
              </Link>
            </div>

            <Navbar />
          </div>

          <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
              <ToolbarWrapper />
              <Content>
                <Outlet />
              </Content>
            </div>
            <FooterWrapper />
          </div>
        </div>
      </div>
    </PageDataProvider>
  )
}

export {Headerlauyout}
