import { Action } from "../actionTypes"

const initState = {
  data: [],
  error:""
}

const store = (state = initState, action: any) => {
  switch (action.type) {
    case Action.USER_PAYMENT_FAIL:
      return {
        ...state,
        data: action?.payload,
        error: action?.error
      }
    default:
      return state
  }
}

export default store
