/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDeletedCards, restoreCard } from '../../../redux/action/card'
import { useSelector } from 'react-redux'
import timeSince from '../../../utils/timesince'
// import { useNavigate } from 'react-router-dom' //omitting due to eslint warning

const TrashCard = () => {
    const dispatch = useDispatch()
    const cards = useSelector((state: any) => state.Card.DeletedCard)

    useEffect(() => {
        dispatch(getDeletedCards())
    }, [])

    const handleRestore = (id: any) => {
        dispatch(restoreCard(id)).then(() => {
            dispatch(getDeletedCards())
        })
    }

    return (
        <div className='row w-100 m-auto'>
            {cards?.map((e) => {
                console.log('Trash Card To Display:', e);
                if ('previewUrl' in e) {
                    return (
                        <div className='col-12 col-md-4 col-lg-3'>
                            <div className='w-100 h-250px position-relative border rounded  d-flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <img
                                        // onClick={() => navigate(`/vcard/compiler/${e._id}`)}
                                        style={{ filter: 'drop-shadow(5px 5px 4px #222222)', maxWidth: '200px', maxHeight: '180px' }}
                                        className='h-100 w-100'
                                        src={e.previewUrl}
                                        alt=''
                                    />
                                    <div style={{ top: '6px', right: '1px' }} className='position-absolute drop-arrow-hide'>
                                        <button className="bg-transparent border-0 dropdown-toggle dropdown-toggle-split more-hover" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="svg-container">
                                                <svg className="svg-icon" width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.599609 2.00002C0.599609 1.1162 1.31578 0.400024 2.19961 0.400024C3.08344 0.400024 3.79961 1.1162 3.79961 2.00002C3.79961 2.88385 3.08344 3.60002 2.19961 3.60002C1.31578 3.60002 0.599609 2.88385 0.599609 2.00002Z" fill="#444444" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39941 2.00002C7.39941 1.1162 8.11559 0.400024 8.99941 0.400024C9.88324 0.400024 10.5994 1.1162 10.5994 2.00002C10.5994 2.88385 9.88324 3.60002 8.99941 3.60002C8.11559 3.60002 7.39941 2.88385 7.39941 2.00002Z" fill="#444444" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1992 2.00002C14.1992 1.1162 14.9154 0.400024 15.7992 0.400024C16.683 0.400024 17.3992 1.1162 17.3992 2.00002C17.3992 2.88385 16.683 3.60002 15.7992 3.60002C14.9154 3.60002 14.1992 2.88385 14.1992 2.00002Z" fill="#444444" />
                                                </svg>

                                                <svg className="overlay-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="15" cy="15" r="15" fill="black" fill-opacity="0" />
                                                </svg>
                                            </div>
                                        </button>
                                        <ul className="dropdown-menu p-3 mt-3">
                                            <li className='cursor-pointer' onClick={() => handleRestore(e._id)}>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path d="M163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3C140.6 6.8 151.7 0 163.8 0zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm192 64c-6.4 0-12.5 2.5-17 7l-80 80c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V408c0 13.3 10.7 24 24 24s24-10.7 24-24V273.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-4.5-4.5-10.6-7-17-7z" /></svg>
                                                    Restore Card
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '11px' }} className='my-2 font-gray1'>
                                    {timeSince(e.updatedAt)}
                                </div>
                            </div>
                        </div>
                    )
                }
                else return <></>
            })}
        </div >
    )
}

export default TrashCard