// import React from 'react' //omitting due to eslint warning
// import { HeaderWrapper } from '../../../../_4threal/layout/components/header' //omitting due to eslint warning
// import { Link } from 'react-router-dom' //omitting due to eslint warning
// import PaymentWarning from '../../../../_4threal/layout/components/sidebar/PaymentWarning' //omitting due to eslint warning
// import FirstHeader from '../../../../_4threal/layout/components/header/FirstHeader' //omitting due to eslint warning
// import { useNavigate } from 'react-router-dom'; //omitting due to eslint warning

function Firstbc() {
  // const navigate = useNavigate(); //omitting due to eslint warning

  const handleLinkClick = (link) => {
    // Reset any global state here before navigating
    //navigate(link);
    window.location.href = link;
  };

  return (
    <>
      <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>

        <div className='py-4 text-center'>
          <h2
            style={{ fontSize: '32px' }}
            className='fw-bolder  font-black fs-1 d-flex align-items-center text-dark'
          >
            Select a marker template
          </h2>

        </div>

        <div className=' w-100 d-flex flex-column  flex-sm-row align-items-center justify-content-center gap-4'>
          <div style={{background:'#e8e8e8', borderRadius:'20px' }} className='border rounded-20 shadow-sm'>
          <div style={{margin:'15px'}} className='row'>
            <div style={{textAlign:'center', fontSize:'20px', fontWeight:'bold', margin:'10px'}}> </div>
          <div style={{textAlign:'center' }} className='col-12 col-sm-6'>
            <div style={{cursor:'pointer'}} onClick={() => handleLinkClick('/vcard/compiler/bc')}>
              <div>
                <img style={{maxHeight:'200px'}} src='/media/bcard.svg' alt="" />
              </div>
                <div style={{ fontSize: '15px', textAlign:'center' }} className='my-2 font-gray2'>
                  Business Card
                </div>
            </div>
            </div>
            <div style={{textAlign:'center' }} className='col-12 col-sm-6'>
            <div style={{cursor:'pointer'}} onClick={() => handleLinkClick('/vcard/compiler/pc')}>
              <div>
                <img style={{maxHeight:'200px'}} src='/media/postcard.svg' alt="" />
              </div>
                <div style={{ fontSize: '15px', textAlign:'center' }} className='my-2 font-gray2'>
                  Post Card
                </div>
            </div>
            </div>
            <div style={{textAlign:'center' }} className='col-12 col-sm-6'>
            <div style={{cursor:'pointer'}} onClick={() => handleLinkClick('/vcard/compiler/qr')}>
              <div>
                <img style={{maxHeight:'200px'}} src='/media/qrcode.svg' alt="" />
              </div>
                <div style={{ fontSize: '15px', textAlign:'center' }} className='my-2 font-gray2'>
                  Decal
                </div>
            </div>
            </div>
            <div style={{textAlign:'center' }} className='col-12 col-sm-6'>
            <div style={{cursor:'pointer'}} onClick={() => handleLinkClick('/vcard/compiler/custom')}>
              <div>
                <img style={{maxHeight:'200px'}} src='/media/tshirt.svg' alt="" />
              </div>
                <div style={{ fontSize: '15px', textAlign:'center' }} className='my-2 font-gray2'>
                  Custom
                </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Firstbc
