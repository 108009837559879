/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createAsset, getAssets, getBlobUrl, updateAsset, uploadBlob } from '../../../../../redux/action/card';
import { generatePopup } from '../../../../../utils/popup';
import { dataURLtoBlob } from '../../../../../_4threal/helpers';
import Dropdown3jsScenes from '../helpers/Dropdown3jsScenes';
import { ReadJsonAtUrl } from '../../../../../_4threal/helpers/ReadJsonAtUrl';
import { Action } from '../../../../../redux/actionTypes';

function Editor() {
  const dispatch = useDispatch();

  // Iframe Stuff
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
  const [editorUrl, setEditorUrl] = useState('');
  const getIFrame = () => { const iframe: any = iframeRef.current; return iframe; }
  const handleResize = () => setIframeHeight(window.innerHeight);

  // 3D Asset Stuff
  const allAssetsRef = useRef([]); //used for backend computations
  const [allAssetsState, setAllAssetsState] = useState([]); //used for frontend computations

  const initialFileName = 'Please select a scene, or click "New Scene"'; //used in a check when page loads
  const activeAssetID = useRef('None selected yet'); //tracks active asset for backend code
  const [activeAssetName, setActiveAssetName] = useState(initialFileName); //tracks active asset for frontend users

  const getActiveAsset: any = () => allAssetsRef.current?.find((x: any) => x._id === activeAssetID.current);

  // AR Cards & Markers
  // const Allcarddata = useSelector((state: any) => state.Card?.UserCard);
  // const getCard = () => Allcarddata.find(x => x._id === Allcarddata[0]._id); //this needs to be updated based on current card

  //Styles
  const iframeStyle = { width: '100%', border: 'none', top: "0px", height: `${iframeHeight - 170}px` };
  const hideStyle = activeAssetName === initialFileName ? { display: 'none' } : {};
  const btnStyle = { margin: '1rem 1rem 1rem 0' };

  //Initialization

  useEffect(() => {
    // Add event listeners
    window.addEventListener('message', handleMessage);
    window.addEventListener('resize', handleResize);

    // Handle first-load operations
    initEditorUrl();
    refreshAssetsList();
    handleResize(); // Call handleResize immediately in case the initial window size is different

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('resize', handleResize);
    }

    // Empty dependency array means this effect runs only once on mount
  }, []);

  // Asset Data Handlers

  const handleMessage = async (event) => {
    // if (event.origin !== "localhost") { return; } // Check the origin if necessary for security
    // if (event.data.type === 'response') { AppLog('Message received: ', event.data.text); }

    switch (event.data.type) {
      case 'saving':
        if (event.data.imgUrl !== undefined) {
          await saveThumbnailToStorage(event.data.imgUrl);
        }
        if (event.data.projectJSON !== undefined) {
          await saveSceneToStorage(event.data.projectJSON);
        }
        break;
      default:
        // AppLog('got message from iframe', event.data);
        break;
    }
  };

  const handleLoadScene = async (sceneContainer) => {
    if (getIFrame()) {
      activeAssetID.current = sceneContainer._id;

      const jsonUrl = await dispatch(
        getBlobUrl({
          filename: `${activeAssetID.current}.${getActiveAsset().scene.name}`,
          dirs: ['assets'],
          setUser: true,
        })
      );

      if (jsonUrl === Action.GET_BLOB_URL_FAIL) {
        generatePopup('error', '3D scene does not exist for this project, please save your current scene');
      } else {
        // Get 3js-editor formatted project json from firebase storage, then send to iframe
        const jsonFromFirebase = await ReadJsonAtUrl(jsonUrl);
        const message = { type: 'loading', projectJSON: jsonFromFirebase };
        getIFrame().contentWindow.postMessage(message, '*');

        setActiveAssetName(getActiveAsset().scene.name);
        generatePopup('success', 'Project is has been loaded');
      }
    }
  }

  const handleSaveScene = async () => {
    // Ping the event listener in 3js-editor to grab the current project state,
    // Then the projectJSON is received in handleMessage()
    const message = { type: 'saving' };
    getIFrame().contentWindow.postMessage(message, '*');
  }

  const handleNewScene = async () => {
    let fileName = prompt("Please enter a file name for the scene:", 'FileName');
    if (fileName === null || fileName === "") {
      generatePopup('error', 'New scene creation canceled');
    } else {
      setActiveAssetName(fileName);

      // Make a new entry in the database
      const newAsset = await dispatch(
        createAsset({
          scene: {
            name: fileName,
            type: 'scene',
            format: 'json',
            url: '',
            thumbUrl: '',
          },
        })
      );

      // Refresh refs immediately after asset creation
      activeAssetID.current = newAsset._id;
      await refreshAssetsList();

      // This will clear the editor and save a new file to Firebase storage
      const message = { type: 'new-file' };
      getIFrame().contentWindow.postMessage(message, '*');
    }
  }

  // const handleAddImagePlane = () => {
  //   const message = { type: 'add-plane', imageUrl: getCard().fullImageUrl };
  //   getIFrame().contentWindow.postMessage(message, '*');
  // }

  // Backend Helper Functions

  const initEditorUrl = () => {
    // Use this code to configure what 3D editor gets loaded into the iframe

    // let url = 'https://4threal-logolive.github.io/StudioEditor/docs/editor/'; //use this for nunuStudio
    let url = 'https://4threal-logolive.github.io/3js-editor/editor/'; //use this for basic 3js-editor

    // const environment = process.env.REACT_APP_ENVIRONMENT;
    if (typeof window !== 'undefined' && process.env.REACT_APP_LOCAL_IP && window.location.hostname === 'localhost') {
      // url = 'http://localhost:8081'; //for nunuStudio
      url = 'http://localhost:8081/editor'; //for 3js-editor
    }

    setEditorUrl(url);
  }

  const saveSceneToStorage = async (json) => {
    const blob = new Blob([JSON.stringify(json)]);
    await dispatch(
      uploadBlob({
        blob: blob,
        filename: `${activeAssetID.current}.${getActiveAsset().scene.name}`,
        dirs: ['assets'],
        setUser: true,
      })
    );

    generatePopup('success', 'Project has been saved');
  }

  const saveThumbnailToStorage = async (imgData) => {
    // Upload the png to storage
    const downloadUrl = await dispatch(
      uploadBlob({
        blob: dataURLtoBlob(imgData),
        filename: `${activeAssetID.current}.thumbnail.png`,
        dirs: ['assets'],
        setUser: true,
      })
    );

    // Update the Firebase doc
    const activeAsset = getActiveAsset();
    await dispatch(
      updateAsset({
        ...activeAsset,
        scene: {
          ...activeAsset.scene,
          thumbUrl: downloadUrl
        }
      })
    );

    // Refresh <img> tags
    await refreshAssetsList();
  }

  const refreshAssetsList = async () => {
    let result = await dispatch(getAssets());
    allAssetsRef.current = result;
    setAllAssetsState(result);
  }

  return (
    <div id='EDITOR CONTAINER'>
      <div style={{ position: "fixed", display: "block", top: "0px", width: '100%', zIndex: 1000 }}>
        {/* <AssetFileList /> */}
        <Dropdown3jsScenes allAssetsState={allAssetsState} handleLoadScene={handleLoadScene} />
        <button className="btn btn-primary" style={btnStyle} onClick={async () => await handleSaveScene()}>Save Scene</button>
        <button className="btn btn-primary" style={btnStyle} onClick={async () => await handleNewScene()}>New Scene</button>
        {/* <button className="btn btn-primary" style={btnStyle} onClick={async () => await handleAddImagePlane()}>Import Marker</button> */}
        <br /> <span className='text-muted'>Active Scene File: {activeAssetName}</span>
        {/* Other Buttons: Create Marker, Generate QR, Add Video/Button/Image/Model */}
      </div>
      <div style={hideStyle}>
        <iframe src={editorUrl} title='Scene Editor' ref={iframeRef} style={iframeStyle} allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default Editor