import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom' //omitted due to eslint warning
import {useFormik} from 'formik'
// import {useDispatch} from 'react-redux' //omitted due to eslint warning
import {useNavigate} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import {sendPasswordResetEmail} from 'firebase/auth'; // Import the required function
import {auth} from '../../../../firebase';
import toast from 'react-hot-toast'; // Import toast

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

export function ForgotPassword() {
  // const dispatch = useDispatch(); //omitted due to eslint warning
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined | any>(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true);
        setHasErrors(undefined);

        await sendPasswordResetEmail(auth, values.email)
          .then(() => {
            setHasErrors(false);
            setLoading(false);
            toast.success('Password Reset Link Sent Successfully, Please check your email.');
            // Wait for 2 seconds before navigating to the login page
            setTimeout(() => {
              navigate('/auth'); // Replace '/login' with the appropriate route
            }, 2000);
            // Optionally navigate to another page or display a success message
          })
          .catch((error) => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus(error.message);
            toast.error('An error occurred. Please try again.');
          });
      } catch (error: any) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus(error.message);
      }
    },
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-start mb-10'>
        <h1 style={{fontSize: '36px'}} className='text-black fw-bolder mb-3'>
          Forgot Password ?
        </h1>
        <div className='text-dark1 fw-semibold fs-6'>Enter your email to reset your password.</div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark1 fs-6'>Email address</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap pb-lg-0'>
        <Button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary w-50 me-4'
        >
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </div>
    </form>
  );
}
