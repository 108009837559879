//Public function used across the whole client-side app to create logs
export function AppLog(...data: (any | undefined)[]) {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  if(data === undefined || data.length === 0) { //if no data is present, throw an error
      console.log("Incorrect usage of AppLog(), there must be data present to create a log");
  }
  else {
    if (environment === 'production') {
      //TODO: add firebase log mechanism
    }
    else {
      console.log(...data);

      //NOTE: Uncomment this line if you want to add the stack trace
      //console.trace();
    }
  }
}