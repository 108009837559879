// src/firebase.ts
// import firebase from 'firebase/app';
// import { getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import prodConfig from './firebase.settings.production.json';
import stagingConfig from './firebase.settings.staging.json';
import { AppLog } from './_4threal/helpers';
import LogRocket from 'logrocket';

const localip:any = process.env.REACT_APP_LOCAL_IP;
const environment = process.env.REACT_APP_ENVIRONMENT;
const tunnels = process.env.REACT_APP_L_URLS;
let parsed_tunnels:any = null;
if (tunnels) {
  parsed_tunnels = JSON.parse(tunnels);
  AppLog('tunnels', parsed_tunnels);
}
let config:any = null;


AppLog('environment', environment);

if (environment === 'production'){
    config = prodConfig;
    LogRocket.init('bt3zpg/4threal');
}else{
    config = stagingConfig;
}

  const app = initializeApp(config);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  const functions = getFunctions(app);


 if (!environment){
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099');        // Auth emulator
    connectFirestoreEmulator(firestore,'localhost', 8080);    // Firestore emulator
    connectStorageEmulator(storage,'localhost', 9199);        // Storage emulator
    connectFunctionsEmulator(functions, 'localhost', 5001);    // Functions emulator
  }

  if (typeof window !== 'undefined' && localip && window.location.hostname === localip) {
    AppLog('run local');
    connectAuthEmulator(auth, `https://${localip}:9099`);        // Auth emulator
    connectFirestoreEmulator(firestore, localip, 8080);    // Firestore emulator
    connectStorageEmulator(storage, localip, 9199);        // Storage emulator
    connectFunctionsEmulator(functions, localip, 5001);    // Functions emulator
  }
}


// const isMobileDevice = () => { return /Mobi|Android|iPhone/i.test(navigator.userAgent); }; //omitting due to eslint warning


export { auth, firestore, storage, functions};