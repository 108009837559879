import {useState, useRef, useEffect} from 'react'
import * as Yup from 'yup'
// import clsx from 'clsx' //omitted due to eslint warning
import {Link} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
// import { requestPassword } from '../core/_requests'
// import {Button} from 'react-bootstrap' //omitted due to eslint warning
import {verifyOtp} from '../../../../redux/action/auth'
import { sendPasswordResetEmail } from 'firebase/auth'
import {auth} from '../../../../firebase'
import {useDispatch} from 'react-redux'
import { AppLog } from '../../../../_4threal/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
})

export function Otp() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location: any = useLocation()
  const [error, setError] = useState(false)
  // const [loading, setLoading] = useState(false) //omitted due to eslint warning
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined) //omitted due to eslint warning

  const [otp, setOTP] = useState(['', '', '', '', '', ''])
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]
  // omitted due to eslint warning
  // const dashicon = {
  //   top: '50% ',
  //   left: '50%',
  //   transform: 'translate(-50%,-50%)',
  //   color: 'black',
  //   fontWeight: 'semibold',
  // }

  //   const handleOtpChange = (e, index) => {
  //     const value = e.target.value
  //     if (value.length <= 1 && /^[0-9]*$/.test(value)) {
  //       const newOtp = [...otp]
  //       newOtp[index] = value
  //       setOTP(newOtp)
  //     }
  //     // Automatically focus on the next input field if a digit is entered
  //     if (/^[0-9]*$/.test(value) && value.length === 1 && index < inputRefs.length) {
  //       inputRefs[index + 1]?.current?.focus()
  //     } else if (value.length === 0) {
  //       inputRefs[index - 1]?.current?.focus()
  //     }
  //   }

  const handleOtpChange = (e, index) => {
    const value = e?.key
    AppLog('value in otp change', value)
    AppLog('index', index)

    if (value === 'Backspace') {
      if (index !== 0) {
        AppLog('focusing on back')
        const previousInput = inputRefs[index - 1]?.current
        AppLog('previous INput', previousInput)
        AppLog('previous input length', previousInput?.value?.length)

        if (previousInput) {
          const inputLength = previousInput?.value?.length
          previousInput.setSelectionRange(inputLength, inputLength)
          previousInput.focus()
        }
      }
      const newOtp = [...otp]
      newOtp[index] = ''
      setOTP(newOtp)
    }

    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp]
      newOtp[index] = value
      setOTP(newOtp)

      if (value.length === 1 && index < inputRefs.length) {
        AppLog('focusing on next')
        inputRefs[index + 1]?.current?.focus()
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        // setLoading(true) //omitted due to eslint warning
        // setHasErrors(undefined) //omitted due to eslint warning
        await sendPasswordResetEmail(auth, values.email)
        // setHasErrors(false) //omitted due to eslint warning
        // setLoading(false) //omitted due to eslint warning
      } catch (error: any) {
        // setHasErrors(true) //omitted due to eslint warning
        // setLoading(false) //omitted due to eslint warning
        setSubmitting(false)
        setStatus(error.message)
      }
      /*
            setTimeout(() => {

              requestPassword(values.email)
                .then(({ data: { result } }) => {
                  setHasErrors(false)
                  setLoading(false)
                })
                .catch(() => {
                  setHasErrors(true)
                  setLoading(false)
                  setSubmitting(false)
                  setStatus('The login detail is incorrect')
                })
            }, 1000)
            */
    },
  })

  useEffect(() => {
    const email = location.state.email
    if (!email) {
      navigate('/auth/forgot-password')
    }
  })

  useEffect(() => {
    const email = location.state.email
    if (!email) {
      navigate('/auth/forgot-password')
    }
    const otpNumber: number = parseInt(otp.join(''), 10)
    const numberStr = otpNumber.toString()
    // Check the length of the string
    const length = numberStr.length
    if (length === 6) {
      const payload = {
        email: email,
        otp: otpNumber,
      }
      dispatch(verifyOtp(payload, navigate)).then((resp) => {
        if (resp?.status === 500) {
          setError(true)
        } else {
          setError(false)
        }
      })
    }
  }, [otp]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className='form w-100  justify-content-center fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-black text-sm-2xl  fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-dark1 fw-semibold fs-6'>
          We’ve sent a 6-character code to{' '}
          <span className='fw-extrabold'>dummyemail@gmail.com.</span>
          The code expires shortly, so please enter it soon.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className={`row justify-content-center ${error ? 'otp-fields1' : 'otp-fields'}`}>
        {otp.map((digit, index) => {
          return (
            <>
              <input
                className='text-center col-2 py-5 text-dark1 fs-1 w-30px w-sm-50px  w-md-50px  '
                type='text'
                inputMode='numeric' // Set inputMode to "numeric"
                pattern='[0-9]*'
                defaultValue={digit}
                placeholder='-'
                key={index}
                value={digit}
                onKeyDown={(e) => {
                  handleOtpChange(e, index)
                }}
                onPaste={(event) => {
                  const otp: any = event.clipboardData.getData('text')

                  if (otp.length === 6) {
                    let newOtp = []
                    for (let i = 0; i < otp.length; i++) {
                      if (Number(otp[i])) {
                        // newOtp.push(otp[i])
                      } else {
                        break
                      }
                    }
                    if (newOtp.length !== 0) {
                      setOTP(newOtp)
                      inputRefs[otp.length - 1]?.current?.focus()
                    }
                  }
                }}
                maxLength={1}
                ref={inputRefs[index]}
              />
              {/* <input
                className='text-center col-2 py-5 text-dark1 fs-1 w-30px w-sm-50px  w-md-50px  '
                type='text'
                // style={{ boxSizing: 'border-box' }}
                key={index}
                defaultValue={digit}
                placeholder='-'
                onChange={(e) => {
                  handleOtpChange(e, index)
                }}
                maxLength={1}
                ref={inputRefs[index]}
              /> */}
            </>
          )
        })}
      </div>
      {/* end::Form group */}

      {error && (
        <div className='text-center mt-6'>
          <div className='font-red'>Wrong passcode. Try again</div>
        </div>
      )}

      <div className='container d-flex align-items-center justify-content-center gap-5 mt-20'>
        <div className='d-flex align-items-center gap-3'>
          <svg
            width='16'
            height='12'
            viewBox='0 0 16 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.09091 12.0025H3.63637V5.82072L1.96956 2.91016L0 3.09344V10.9116C0 11.5143 0.488187 12.0025 1.09091 12.0025Z'
              fill='#0085F7'
            />
            <path
              d='M12.3636 12.0025H14.9091C15.5118 12.0025 16 11.5143 16 10.9116V3.09344L14.0333 2.91016L12.3637 5.82072V12.0025H12.3636Z'
              fill='#00A94B'
            />
            <path
              d='M12.3636 1.09384L10.8684 3.94706L12.3636 5.82112L16 3.09384V1.63931C16 0.29112 14.4609 -0.47888 13.3818 0.330214L12.3636 1.09384Z'
              fill='#FFBC00'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M3.63633 5.82103L2.21155 2.81134L3.63633 1.09375L7.99995 4.36647L12.3636 1.09375V5.82103L7.99995 9.09375L3.63633 5.82103Z'
              fill='#FF4131'
            />
            <path
              d='M0 1.63931V3.09384L3.63637 5.82112V1.09384L2.61819 0.330214C1.53909 -0.47888 0 0.29112 0 1.63931H0Z'
              fill='#E51C19'
            />
          </svg>
          <Link className='text-decoration-underline font-dark1' to=''>
            Open Gmail
          </Link>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <svg
            width='16'
            height='15'
            viewBox='0 0 16 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M16 7.81341C16.0004 7.7519 15.9849 7.69132 15.9551 7.63754C15.9252 7.58376 15.882 7.5386 15.8295 7.50643H15.8277L15.821 7.50271L10.2768 4.22085C10.2529 4.20468 10.228 4.1899 10.2024 4.17658C10.0969 4.12215 9.97988 4.09375 9.86117 4.09375C9.74245 4.09375 9.62546 4.12215 9.51996 4.17658C9.49433 4.1899 9.46948 4.20468 9.44554 4.22085L3.90137 7.50271L3.89468 7.50643C3.72523 7.61181 3.67328 7.8346 3.77865 8.00405C3.8097 8.05397 3.85246 8.09557 3.90323 8.12522L9.44742 11.4071C9.47144 11.4231 9.49628 11.4379 9.52184 11.4514C9.62734 11.5058 9.74433 11.5342 9.86305 11.5342C9.98176 11.5342 10.0988 11.5058 10.2043 11.4514C10.2298 11.4379 10.2547 11.4231 10.2787 11.4071L15.8229 8.12522C15.8773 8.0935 15.9223 8.04794 15.9534 7.99318C15.9845 7.93841 16.0006 7.87639 16 7.81341Z'
              fill='#0A2767'
            />
            <path
              d='M4.54775 5.62269H8.18607V8.95776H4.54775V5.62269ZM15.2558 2.23256V0.706973C15.2646 0.325542 14.9627 0.00913994 14.5812 0H5.13976C4.75833 0.00913994 4.45641 0.325542 4.46515 0.706973V2.23256L10.0465 3.72092L15.2558 2.23256Z'
              fill='#0364B8'
            />
            <path d='M4.46509 2.23438H8.18601V5.58321H4.46509V2.23438Z' fill='#0078D4' />
            <path
              d='M11.907 2.23438H8.18604V5.58321L11.907 8.93204H15.2558V5.58321L11.907 2.23438Z'
              fill='#28A8EA'
            />
            <path d='M8.18604 5.58203H11.907V8.93087H8.18604V5.58203Z' fill='#0078D4' />
            <path d='M8.18604 8.92969H11.907V12.2785H8.18604V8.92969Z' fill='#0364B8' />
            <path d='M4.54773 8.95703H8.18605V11.9888H4.54773V8.95703Z' fill='#14447D' />
            <path d='M11.907 8.92969H15.2558V12.2785H11.907V8.92969Z' fill='#0078D4' />
            <path
              d='M15.8296 8.10385L15.8225 8.10757L10.2783 11.2257C10.2541 11.2406 10.2296 11.2547 10.2039 11.2674C10.1097 11.3122 10.0075 11.3375 9.90327 11.3418L9.60038 11.1647C9.57479 11.1518 9.54994 11.1375 9.52596 11.1219L3.90736 7.9152H3.90476L3.72095 7.8125V14.1247C3.72381 14.5458 4.06743 14.8849 4.48857 14.8823H15.2443C15.2506 14.8823 15.2562 14.8793 15.2629 14.8793C15.3518 14.8736 15.4395 14.8553 15.5233 14.825C15.5596 14.8096 15.5945 14.7915 15.6279 14.7706C15.6528 14.7565 15.6956 14.7256 15.6956 14.7256C15.7896 14.6561 15.8661 14.5655 15.9189 14.4612C15.9718 14.3569 15.9995 14.2416 16 14.1247V7.8125C15.9999 7.8718 15.9841 7.93003 15.9542 7.98122C15.9242 8.03241 15.8812 8.07473 15.8296 8.10385Z'
              fill='url(#paint0_linear_27_1370)'
            />
            <path
              opacity='0.5'
              d='M15.7024 7.78823V8.17521L9.90516 12.1666L3.90329 7.91846C3.90329 7.91747 3.9029 7.91653 3.9022 7.91583C3.90151 7.91513 3.90056 7.91474 3.89957 7.91474L3.34888 7.58358V7.3045L3.57586 7.30078L4.05585 7.57613L4.06701 7.57986L4.10794 7.6059C4.10794 7.6059 9.74886 10.8245 9.76375 10.8319L9.97956 10.9585C9.99816 10.951 10.0168 10.9436 10.0391 10.9361C10.0503 10.9287 15.6391 7.7845 15.6391 7.7845L15.7024 7.78823Z'
              fill='#0A2767'
            />
            <path
              d='M15.8296 8.10385L15.8225 8.10794L10.2783 11.2261C10.2541 11.241 10.2296 11.2551 10.2039 11.2677C10.0977 11.3197 9.98095 11.3466 9.8627 11.3466C9.74444 11.3466 9.62774 11.3197 9.52149 11.2677C9.49599 11.2551 9.47115 11.2412 9.44707 11.2261L3.9029 8.10794L3.8962 8.10385C3.84364 8.07535 3.79967 8.0333 3.76885 7.98207C3.73803 7.93084 3.72149 7.87229 3.72095 7.8125V14.1247C3.72361 14.5458 4.06711 14.8849 4.48817 14.8823H15.2328C15.6538 14.885 15.9973 14.5458 16 14.1247C16 14.1247 16 14.1247 16 14.1247V7.8125C15.9999 7.8718 15.9841 7.93003 15.9542 7.98121C15.9242 8.0324 15.8812 8.07472 15.8296 8.10385Z'
              fill='#1490DF'
            />
            <path
              opacity='0.1'
              d='M10.359 11.1797L10.2761 11.2262C10.252 11.2415 10.2272 11.2555 10.2016 11.2682C10.1102 11.3131 10.0108 11.3394 9.90918 11.3456L12.0186 13.8402L15.6982 14.7268C15.799 14.6507 15.8792 14.5505 15.9315 14.4355L10.359 11.1797Z'
              fill='black'
            />
            <path
              opacity='0.05'
              d='M10.7349 10.9688L10.2761 11.2266C10.252 11.2419 10.2272 11.256 10.2016 11.2687C10.1102 11.3135 10.0108 11.3398 9.90918 11.346L10.8975 14.0709L15.6993 14.7261C15.7926 14.6561 15.8684 14.5653 15.9205 14.4609C15.9727 14.3566 15.9999 14.2415 16 14.1248V14.0433L10.7349 10.9688Z'
              fill='black'
            />
            <path
              d='M4.49862 14.8823H15.2316C15.3968 14.8831 15.5579 14.8309 15.6912 14.7334L9.60001 11.1654C9.57441 11.1526 9.54956 11.1383 9.52559 11.1226L3.90699 7.91594H3.90438L3.72095 7.8125V14.1031C3.72053 14.533 4.0687 14.8818 4.49862 14.8823C4.49861 14.8823 4.49861 14.8823 4.49862 14.8823Z'
              fill='#28A8EA'
            />
            <path
              opacity='0.1'
              d='M8.93025 3.65885V11.5956C8.92958 11.8739 8.76038 12.124 8.50234 12.2282C8.42241 12.2625 8.33632 12.2802 8.24931 12.2802H3.72095V3.3489H4.46514V2.97681H8.24932C8.62522 2.97823 8.92944 3.28294 8.93025 3.65885Z'
              fill='black'
            />
            <path
              opacity='0.2'
              d='M8.55816 4.02973V11.9665C8.5591 12.0564 8.54003 12.1453 8.50234 12.2269C8.399 12.4816 8.1521 12.6487 7.87722 12.65H3.72095V3.34769H7.87722C7.98519 3.34661 8.0916 3.37353 8.18606 3.42583C8.41416 3.54074 8.55807 3.77433 8.55816 4.02973Z'
              fill='black'
            />
            <path
              opacity='0.2'
              d='M8.55816 4.02973V11.2223C8.55634 11.598 8.25296 11.9026 7.87723 11.9058H3.72095V3.34769H7.87722C7.98519 3.34661 8.0916 3.37353 8.18606 3.42583C8.41416 3.54074 8.55807 3.77433 8.55816 4.02973Z'
              fill='black'
            />
            <path
              opacity='0.2'
              d='M8.18606 4.0297V11.2223C8.18566 11.5986 7.88144 11.9039 7.50513 11.9058H3.72095V3.34766H7.50513C7.8814 3.34786 8.18626 3.65306 8.18605 4.02933C8.18606 4.02946 8.18606 4.02958 8.18606 4.0297Z'
              fill='black'
            />
            <path
              d='M0.682044 3.34766H7.50399C7.88068 3.34766 8.18604 3.65302 8.18604 4.0297V10.8516C8.18604 11.2283 7.88068 11.5337 7.50399 11.5337H0.682044C0.305358 11.5337 0 11.2283 0 10.8516V4.0297C0 3.65302 0.305367 3.34766 0.682044 3.34766Z'
              fill='url(#paint1_linear_27_1370)'
            />
            <path
              d='M2.1317 6.21101C2.2998 5.85286 2.57114 5.55314 2.91087 5.35036C3.28709 5.13497 3.71547 5.02758 4.14882 5.04003C4.55046 5.03132 4.94678 5.13314 5.29449 5.33436C5.62142 5.52932 5.88472 5.81498 6.05244 6.15668C6.2351 6.5332 6.32614 6.94757 6.31812 7.36598C6.32697 7.80326 6.23331 8.23653 6.04463 8.6311C5.87291 8.98501 5.60126 9.28086 5.26324 9.48207C4.90212 9.68945 4.49109 9.79395 4.07477 9.78421C3.66455 9.79411 3.25949 9.69114 2.9038 9.48654C2.57404 9.29132 2.30744 9.00533 2.1358 8.66272C1.95206 8.29164 1.85994 7.88194 1.86715 7.46793C1.85949 7.03438 1.94993 6.6047 2.1317 6.21101ZM2.96221 8.23147C3.05184 8.45789 3.20382 8.65426 3.40053 8.7978C3.60089 8.93784 3.84081 9.01007 4.08518 9.00394C4.34543 9.01423 4.60195 8.93952 4.81597 8.7911C5.01019 8.64801 5.15819 8.45111 5.24165 8.22477C5.33494 7.972 5.38098 7.70424 5.37746 7.43482C5.38034 7.16283 5.33706 6.89233 5.24946 6.63482C5.17208 6.40233 5.02886 6.19732 4.83718 6.04469C4.62852 5.88923 4.37268 5.81039 4.11271 5.82143C3.86305 5.81496 3.61776 5.88778 3.41207 6.02943C3.21203 6.17357 3.05716 6.37168 2.96556 6.60059C2.76236 7.12529 2.76129 7.70677 2.96258 8.23221L2.96221 8.23147Z'
              fill='white'
            />
            <path d='M11.907 2.23438H15.2558V5.58321H11.907V2.23438Z' fill='#50D9FF' />
            <defs>
              <linearGradient
                id='paint0_linear_27_1370'
                x1='9.86048'
                y1='7.8125'
                x2='9.86048'
                y2='14.8823'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#35B8F1' />
                <stop offset='1' stop-color='#28A8EA' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_27_1370'
                x1='1.42208'
                y1='2.81472'
                x2='6.76396'
                y2='12.0666'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#1784D9' />
                <stop offset='0.5' stop-color='#107AD5' />
                <stop offset='1' stop-color='#0A63C9' />
              </linearGradient>
            </defs>
          </svg>

          <Link className='text-decoration-underline font-dark1' to=''>
            Open Outlook
          </Link>
        </div>
      </div>

      <p className='font-dark1 text-center mt-4'>Can’t find the code? Check your spam folder!</p>

      <div className='font-dark1 text-center  fs-6 mt-10'>
        Don’t received yet?{' '}
        <Link to='' className='text-decoration-underline font-dark1  fw-bolder'>
          Resend Code
        </Link>
      </div>
    </form>
  )
}
