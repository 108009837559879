import React from 'react'
import { Modal } from 'react-bootstrap'

const DeleteCardModal = ({ open, card, type, handleClose, del, rename, rtext, pname, deleteType='card' }) => {


  return <div>
    <Modal className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={open}
      animation={false}
      onHide={handleClose}
      centered
    >
      {type === 'delete' ? (
        <div className='modal-content p-5'>
          <div className='d-flex align-items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "red" }} className='mb-2' height="20" width="20" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" /></svg>
            <h1>Delete Card</h1>
          </div>
          <h4 className='py-3'>Are you sure you want to delete this {deleteType}?</h4>
          <div className='d-flex justify-content-end gap-3'>
            <button type="button" className="btn btn-primary" onClick={del}>Delete</button>
            <button type="button" className="btn btn-light" onClick={handleClose}>Cancel</button>
          </div>
        </div>
      ) : (
        <div className='modal-content p-5'>
          <div className='d-flex align-items-center gap-2'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.333 3.333H6.667v1.667H4.167a2.5 2.5 0 0 0 -2.5 2.5v5a2.5 2.5 0 0 0 2.5 2.5h2.5v1.667h1.667zM6.667 6.667v6.667H4.167a0.833 0.833 0 0 1 -0.833 -0.833V7.5a0.833 0.833 0 0 1 0.833 -0.833z" fill="currentColor" /><path d="M15.833 13.333h-5.833v1.667h5.833a2.5 2.5 0 0 0 2.5 -2.5V7.5a2.5 2.5 0 0 0 -2.5 -2.5h-5.833v1.667h5.833a0.833 0.833 0 0 1 0.833 0.833v5a0.833 0.833 0 0 1 -0.833 0.833" fill="currentColor" /></svg>
            <h1>Rename Card</h1>
          </div>
          <h4 className='py-3'>Please enter a new name.</h4>
          <input
            className='form-control bg-transparent border-1'
            onChange={(e) => rtext(e.target.value)}
            value={pname}
          />
          <div className='d-flex justify-content-end gap-3'>
            <button type="button" className="btn btn-primary" onClick={rename}>Rename</button>
            <button type="button" className="btn btn-light" onClick={handleClose}>Cancel</button>
          </div>
        </div>
      )}
    </Modal>
  </div>
}

export default DeleteCardModal
