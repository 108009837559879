
function Dropdown3jsScenes(props) {
  const { allAssetsState, handleLoadScene } = props;

  return <>
    <button className="btn btn-primary dropdown-toggle dropdown-toggle-split more-hover"
      style={{ margin: '1rem 1rem 1rem 0' }} data-bs-toggle="dropdown" aria-expanded="false">
      Load Scene
    </button>
    <ul className="dropdown-menu p-4 mt-3 w-10 px-3">
      {allAssetsState?.length === 0 && <li>Your asset collection is empty, please create a new asset first!</li>}
      {allAssetsState?.map((item: any, i) => {
        return (
          <li className='cursor-pointer' onClick={async () => await handleLoadScene(item)} key={i}>
            <div className='gap-2 align-items-center' style={{ padding: '5px' }}>
              {item.scene.name}<br/>
              <img width='100px' src={item.scene.thumbUrl} alt='' />
            </div>
          </li>
        )
      })}
    </ul>
  </>
}

export default Dropdown3jsScenes