import { Action } from "../actionTypes";

const initState = {
    usePlan: [],
    singleplan: [],
    error: "",
};
const store = (state = initState, action: any) => {
    // console.log('state,action :>> ', state, action);
    switch (action.type) {
        case Action.GET_ALL_PLAN_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_PLAN_SUCCESS:
            return {
                ...state,
                usePlan: action?.payload
            }
        case Action.GET_ALL_PLAN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_SINGLE_PLAN_INIT:
            return {
                ...state
            }
        case Action.GET_SINGLE_PLAN_SUCCESS:
            return {
                ...state,
                singleplan: action?.payload
            }
        case Action.GET_SINGLE_PLAN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}

export default store;
