import { API_URL } from '../../config'
import { generatePopup } from '../../utils/popup'
import axios from 'axios'
import { AppLog } from '../../_4threal/helpers'
import { Action } from '../actionTypes'
import { auth, firestore } from '../../firebase'
import {
  // getAuth,GoogleAuthProvider, signInWithPopup,sendPasswordResetEmail
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import {
  // serverTimestamp, setDoc,
  doc,
  collection,
  getDocs,
  getDoc,
  where,
  query,
  setDoc,
} from 'firebase/firestore'

export const authStateChanged = (user: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Action.AUTH_STATE_CHANGED,
      payload: user,
    })

    if (!user) {
      // If the user is logged out
      localStorage.removeItem("userId");
      window.location.replace("/auth");
    }
  }
}
/*
const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true)
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

    } catch (error: any) {
      saveAuth(undefined)
      setLoading(false)
      // Handle Errors here.
      console.error('Error signing in with Google: ', error.message);
    }
  };
*/

export const authSignup: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: Action.AUTH_REGISTER_INIT,
    })
    try {
      const {
        email,
        password,
        firstName,
        lastName,
        // Terms
      } = payload

      // Create the user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      //   const user = userCredential.user
      // if (user) {
      //     localStorage.setItem('userId', user.uid);
      // }

      // Update the user's display name
      await updateProfile(userCredential.user, { displayName: firstName + ' ' + lastName })

      generatePopup('success', 'User registered successfully.')

      resolve(payload)
    } catch (error: any) {
      // Check the Firebase error code
      if (error.code === 'auth/email-already-in-use') {
        // Handle the case of invalid email/password
        generatePopup('error', 'Email already registered!')
      } else {
        // Handle other Firebase authentication errors
        generatePopup('error', 'An error occurred. Please try again later.')
      }

      dispatch({
        type: Action.AUTH_REGISTER_FAIL,
        payload: typeof error === 'string' ? error : 'User already registered!',
      })
      resolve(error)
    }
  })
}

export const betaSignup: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: Action.BETA_REGISTER_INIT,
    })
    try {
      const cardRef = doc(firestore, `beta_requests/${payload.email}`)
      await setDoc(cardRef, payload)

      dispatch({
        type: Action.BETA_REGISTER_SUCCESS,
        payload: payload,
      })
      generatePopup('success', 'Thank you for signing up for the beta!')
      resolve(payload)
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        generatePopup('error', 'Email already registered!')
      } else {
        generatePopup('error', 'An error occurred. Please try again later.')
        console.log(error);
      }

      dispatch({
        type: Action.BETA_REGISTER_FAIL,
        payload: error,
      })
      resolve(error)
    }
  })
}

export const authLogin: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: Action.AUTH_LOGIN_INIT,
    })

    try {
      const userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password)
      const uid = userCredential.user.uid
      const userDoc = await getDoc(doc(firestore, 'users', uid))
      const userData = userDoc.data()
      if (userData) {
        await dispatch({
          type: Action.AUTH_LOGIN_SUCCESS,
          payload: userCredential.user,
        })
        generatePopup('success', 'User Login successfully.')
        resolve(userCredential.user)

      } else {
        generatePopup(
          'error',
          'Your account has been Inactive. Contact your 4threal support team  to active it, then try again.'
        )
      }
    } catch (error: any) {
      // Check the Firebase error code
      if (error.code === 'auth/invalid-login-credentials') {
        // Handle the case of invalid email/password
        generatePopup('error', 'Invalid email or password')
      } else if (error.code === 'auth/user-not-found') {
        generatePopup('error', 'This user not found')
      } else if (error.code === 'auth/wrong-password') {
        generatePopup('error', 'Your password is wrong')
      } else {
        // Handle other Firebase authentication errors
        generatePopup('error', 'An error occurred. Please try again later.')
      }

      dispatch({
        type: Action.AUTH_LOGIN_FAIL,
        payload: error.message, // Use error.message for Firebase errors
      })

      reject(error) // Reject with the error data
    }
  })
}

export const sendForgotPassword: any = (payload: any, navigate: any) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_FUNCTION_URL + `/sendOTPViaEmail`, payload, {
        headers: {
          'Content-Type': 'application/json', // Set the Content-Type header
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          generatePopup('success', res?.data?.message)
          const email = payload.email
          navigate('/auth/otp', { state: { email } })
        } else {
          AppLog('res', res)
        }
        resolve(res?.data)
      })
      .catch((error: any) => {
        if (error) {
          generatePopup('error', error?.response?.data?.message)
        }
        resolve(error?.response?.data)
      })
  })
}

export const verifyOtp: any = (payload: any, navigate: any) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_FUNCTION_URL + `/verifyOTPViaEmail`, payload, {
        headers: {
          'Content-Type': 'application/json', // Set the Content-Type header
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          generatePopup('success', res?.data?.message)
          // const email = payload.email //omitting due to eslint warning
          navigate('/auth/dashboard')
        } else {
          navigate('/auth')
        }
        resolve(res?.data)
      })
      .catch((error: any) => {
        if (error) {
          generatePopup('error', error?.response?.data?.message)
        }
        resolve(error?.response?.data)
      })
  })
}

export const passwordChange: any = (payload: any) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem('authToken')
    dispatch({
      type: Action.PASSWORD_CHANGE_INIT,
    })
    axios
      .post(`${API_URL}/api/users/change/password`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: Action.PASSWORD_CHANGE_SUCCESS,
            payload: res?.data,
          })
          resolve(res?.data)
        } else {
          dispatch({
            type: Action.PASSWORD_CHANGE_FAIL,
            payload: res?.data?.message || 'Failed to user change password!',
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch({
            type: Action.PASSWORD_CHANGE_FAIL,
          })
        } else if (error?.response?.status === 401) {
          generatePopup('error', 'Token is invalid or expired.')
          localStorage.clear()
          window.location.replace('/')
        }
      })
  })
}

export const userProfile: any = (payload: any) => async (dispatch: any) => {
  try {
    const userDoc = await getDoc(doc(firestore, 'users', payload))
    if (userDoc.exists()) {
      const transactionsQuerySnapshot = await getDocs(
        query(
          collection(firestore, 'transactions'),
          where('userId', '==', payload),
          where('status', '==', 'Success')
        )
      )

      const transactions: any[] = []
      transactionsQuerySnapshot.forEach((doc) => {
        transactions.push(doc.data() as any)
      })

      dispatch({
        type: Action.GET_LOGINUSER_PROFILE_SUCCESS,
        payload: {
          userData: userDoc.data(),
          payments: transactions ? transactions : [],
        },
      })
    } else {
      throw new Error('User not found')
    }
  } catch (error: any) {
    dispatch({
      type: Action.GET_LOGINUSER_PROFILE_FAIL,
      payload: error.message,
    })
    // window.location.replace("/auth");
  }
}

export const paymentStatus: any = (payload: any) => async (dispatch: any) => {
  try {
    const userDoc = await getDoc(doc(firestore, 'users', payload))
    if (userDoc.exists()) {
      const transactionsQuerySnapshot = await getDocs(
        query(
          collection(firestore, 'transactions'),
        )
      )

      const transactions: any[] = []
      transactionsQuerySnapshot.forEach((doc) => {
        transactions.push(doc.data() as any)
      })

      dispatch({
        type: Action.GET_PAYMENT_STATUS_SUCCESS,
        payload: {
          userData: userDoc.data(),
          payments: transactions
        },
      })
    } else {
      throw new Error('User not found')
    }
  } catch (error: any) {
    dispatch({
      type: Action.GET_PAYMENT_STATUS_FAIL,
      payload: error.message,
    })
    // window.location.replace("/auth");
  }
}


