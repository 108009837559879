import clsx from 'clsx'
import { makeProfilePic } from '../../../helpers' //omitting, not used
import { HeaderUserMenu } from '../../../partials'
import { useSelector } from 'react-redux'
// import { useLayout } from '../../core' //omitting, not used
// import { KTIcon, toAbsoluteUrl } from '../../../helpers' //omitting, not used
// import {createHash} from 'crypto-browserify' //omitting, not used

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
// const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
// const btnIconClass = 'fs-2' //omitting, not used

const Navbar = () => {

  // const { config } = useLayout() //omitting, not used
  const userdata = useSelector((state: any) => state.Auth?.authProfile?.userData);

  return (
    <div className='app-navbar d-flex  align-items-center gap-4'>

      {/* <div className="dropdown d-flex align-items-center pe-4 border-2 border-end ">
        <button style={{ backgroundColor: "#0069E3" }} className="btn  btn-sm dropdown-toggle nav-dropdown text-light d-flex align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="bi bi-plus-square-fill text-light me-2 fs-2"></i>
          <div className=' d-none d-md-block'>Create New</div>
          <i className="bi bi-chevron-down text-light ms-2"></i>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/vcard/compiler/bc">Business Card</a>
          <a className="dropdown-item" href="/vcard/compiler/pc">Post Card</a>
        </div>
      </div> */}

      {/* <div>
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 20C13 21.7 11.7 23 10 23C8.3 23 7 21.7 7 20" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M17 13V8C17 4.1 13.9 1 10 1C6.1 1 3 4.1 3 8V13C3 17 1 20 1 20H19C19 20 17 17 17 13V13Z" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="16" cy="4" r="3.5" fill="#FF5353" stroke="white" />
        </svg>
      </div> */}


      <div className={clsx('app-navbar-item d-flex', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img className='rounded-circle' src={makeProfilePic(userdata?.protected?.email)} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
      {/*
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='tMPext-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Navbar }
