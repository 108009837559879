import { legacy_createStore as createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
// import { onAuthStateChanged } from 'firebase/auth';
// import { authStateChanged } from './action/auth';  // Adjust path
// import { auth } from '../firebase';  // Adjust path
import authReducers from "../redux/reducers/authReducer";
import planReducer from "../redux/reducers/planReducer";
import cardReducer from "../redux/reducers/cardReducer";
import adminReducer from "../redux/reducers/adminReducer";
import paymentReducer from '../redux/reducers/paymentReducer'
import apiReducer from '../redux/reducers/apiReducer'

const rootReducer = combineReducers({
    Auth: authReducers,
    Plan: planReducer,
    Card: cardReducer,
    Admin: adminReducer,
    Payment: paymentReducer,
    Api: apiReducer
});

const logger = (store: any) => (next: any) => (action: any) => {
    let result = next(action);
    console.groupEnd();
    return result;
};

const intialState = {};

const middlewares = [logger, thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers: any = compose(...enhancers);

const store = createStore(rootReducer, intialState, composedEnhancers);

// onAuthStateChanged listener:
// const unsubscribe = onAuthStateChanged(auth, (user) => {
//     store.dispatch(authStateChanged(user));
// });

export default store;
