import {
  //  FC,useState
  useEffect,
} from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import {
  Logout,
  AuthPage,
  // useAuth
} from '../modules/auth'
import { App } from '../App'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
// import { decodeToken, isExpired } from "react-jwt";
import { useDispatch } from 'react-redux'
import React from 'react'
import { userProfile } from '../../redux/action/auth'
import ARScene from '../experiences/ARScene'
import ExternalRedirect from './ExternalRedirect'
import LogRocket from 'logrocket';

// import Camera from '../../examples/image-tracking/nft/camera'
// import { auth } from '../../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
const { PUBLIC_URL } = process.env

const AppRoutes: React.FC = () => {
  // const [user, setUser] = React.useState<null | Record<string, any>>(null);
  const [loading, setLoading] = React.useState(true)
  const [token, setToken] = React.useState(false)
  const dispatch = useDispatch()

  // React.useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //     dispatch(userProfile(currentUser?.uid))
  //     setLoading(false);
  //   });
  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      setLoading(true)
      if (user) {
        localStorage.setItem('userId', user?.uid)
        dispatch(userProfile(user?.uid))
        //console.log('user', user)
        LogRocket.identify(user?.uid, {
          name: user?.displayName || 'Unknown',
          email: user?.email || 'Unknown',
        });
        setToken(true)
        setLoading(false)
      } else {
        localStorage.removeItem('userId')
        setToken(false)
        setLoading(false)
        // User is signed out
        // Handle unauthenticated logic
      }
    })
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <div>Loading...</div>
  }

  const hostname = window.location.hostname


const ARSceneWrapper = ({ isPreview }) => {
  // You can also use useParams here if you need to access :id
  return <ARScene isPreview={isPreview} />;
};

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {hostname === 'qr2xr.com' ? (
            <>
              <Route path='/ar/:id' element={<ARSceneWrapper isPreview={false} />} />
              <Route path='*' element={<ExternalRedirect to='https://scanjunkie.com' />} />
            </>
          ) : token ? (
            <>
              <Route path='/ar/:id' element={<ARScene />} />
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='/ar/:id' element={<ARScene />} />
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
