import React from 'react';
import { KTIcon } from '../_4threal/helpers';
import toast, { 
    // Toaster 
} from 'react-hot-toast';
// import { Button } from 'react-bootstrap';

export const generatePopup = (type, msg) => {
    // const duration = 10000;
    if (type === 'success') {
        return toast.success((t) => (
            <div className='d-flex align-items-center gap-2'>
                <div>
                    {msg}
                </div>
                <div>
                    <button onClick={() => toast.dismiss(t.id)} className='btn-sm bg-body text-white rounded border-0'>
                        <KTIcon iconName='cross' className='fs-1' />
                    </button>
                </div>
            </div>
        ));
    } else {
        return toast.error((t) => (
            <div className='d-flex align-items-center gap-2'>
                <div>
                    {msg}
                </div>
                <div>
                    <button onClick={() => toast.dismiss(t.id)} className='btn-sm bg-body text-white rounded border-0'>
                        <KTIcon iconName='cross' className='fs-1' />
                    </button>
                </div>
            </div>
        ));
    }
};