import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './status.css';

type statusProps = {
    markerImg?: string
    isLoading: boolean
    isScanning: boolean
    isProcessing: boolean
    isDemo: boolean
    needsTap: boolean
    isError: boolean
    contentVisible: boolean
    isPublished?: boolean
    progress?: number
    hasTapped?: () => void;
    viewExp?: () => void;
}

const StatusOverlay: React.FC<statusProps> = (({ markerImg, isLoading, needsTap, isScanning, isProcessing, isError, contentVisible, isPublished, isDemo, progress = 0, hasTapped, viewExp }) => {
    const firstImageOpacity = progress <= 1 ? 0 : progress >= 60 ? 1 : (progress - 40) / 100;
    const secondImageOpacity = progress <= 40 ? 0 : progress >= 100 ? 1 : (progress) / 100;
    const percentage = progress;
    console.log("percentage", percentage);
    // const scaleFactor = 1 + (1.6 - (percentage / 100)); // omitting due to eslint warning

    // omitting due to eslint warning
    // const transformStyle = {
    //     transform: `scaleX(${scaleFactor})`,
    //     transformOrigin: 'left center', // Ensures scaling from the left
    // };

    return (
        <>
            {
                (isLoading || needsTap) &&
                (<div style={{ cursor: 'pointer' }} onClick={needsTap ? hasTapped : () => { }} className="mindar-ui-overlay mindar-ui-loading">
                    <div className="stat-window">
                        <div className="inner" >
                            <div style={{ width: "450px", height: "5%" }}>
                                <img style={{ height: '80%' }} src="/media/splash/xrlogo.svg" alt="" />
                            </div>
                            { isPublished || isDemo ? (
                                <>
                                <div style={{ width: "450px", height: "40%", padding: '10px' }}>
                                    <video autoPlay loop muted playsInline style={{ height: '100%' }}><source src="/media/splash/iconanim.mp4" type="video/mp4" /></video>
                                </div>
                                { progress < 100 ? (
                                     <>
                                <div style={{ width: "450px", height: "15%", padding: '10px' }}>
                                    <img style={{ height: '100%', opacity: firstImageOpacity, transition: 'opacity 0.5s ease-in-out' }} src="/media/splash/loading.svg" alt="" />
                                </div>
                                 <div style={{ width: "450px", height: "5%", paddingLeft: '30px', paddingRight: '30px' }}>
                                    <ProgressBar animated now={progress} />
                                </div>
                                </>
                               ) : (
                                    <div style={{ width: "450px", height: "20%", paddingLeft: '30px', paddingRight: '30px', fontSize:'25px', paddingTop:"50px" }}>
                                        PRESS PLAY
                                    </div>
                                )}
                                 </>
                            ) : (
                                <div style={{ width: "450px", height: "40%", padding: '10px'}}>
                                    <div style={{ color: 'white', marginTop:'100px', fontSize:'28px'}}>This Experience is under construction. You can enjoy it after it has been published.<br></br> Until then, check out <a href="https://www.scanjunkie.com" >scanjunkie.com</a></div>
                                </div>
                            )}
                            <div style={{ width: "450px", height: "30%", padding: '10px', position: "relative" }}>

                                <video autoPlay loop muted playsInline style={{ height: '100%' }}><source src="/media/splash/ball_anim_resize.mp4" type="video/mp4" /></video>
                                <img style={{ height: '60%', opacity: secondImageOpacity, width: '60%', position: "absolute", left: '20.5%', top: '17.5%', transition: 'opacity 0.5s ease-in-out' }} src="/media/splash/play_button.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>)
            }
            {
                (isScanning && !needsTap && !contentVisible && !isLoading) && (
                    <div className="mindar-ui-overlay mindar-ui-scanning">
                        <div className="scanning">
                            <img className="instructions" src="/media/ar/instructions.svg" alt="" />
                            <img className="frame" src="/media/ar/frame.svg" alt="" />
                            <img src="/media/ar/hand.svg" className='hand' alt="" />
                            <img className='checkImg' src="/media/ar/checkbox.svg" alt="" />
                            <img className='markerImg' src={markerImg} alt="" />


                            {/*<button onClick={viewExp} style={{width:'100%', borderRadius:'50px', height:'50px', position:'relative', transform:'scale(100%) translateX(0%) translateY(-1200%)'}}>View experience without scanning</button>*/}
                        </div>
                    </div>
                )
            }
        </>
    )
})

export default StatusOverlay