import React from 'react'
import "../../../_4threal/assets/sass/layout/_vrcards.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/scss/navigation';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { AppLog, toAbsoluteUrl } from '../../../_4threal/helpers';

function Vrcards() {

    const [selectedImage, setSelectedImage] = React.useState(null);
    const [logo, setLogo] = React.useState(false);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl: any = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const imageUrl: any = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };

    const handlelogo = () => {
        setLogo(!logo)
    }


    return (
        <>
            <div className='row'>
                <div className='col-12 col-lg-6 bg-none'>

                    <div className='rounded-4 border shadow  main-card'>
                        <div>
                            <div>
                                <input type="text" className='p-1  w-200px w-md-250px border-0 card-input' />
                            </div>
                            <div className='mt-3'>
                                <input type="text" className='p-1 w-150px w-md-200px border-0 card-input' />
                            </div>
                            <div className='mt-3'>
                                <input type="text" className='p-1 w-100px  w-md-150px border-0 card-input' />
                            </div>
                        </div>


                        <div className='d-flex w-100 justify-content-between align-items-end mt-5 mt-sm-5 mt-md-5'>
                            <div className='d-flex gap-2'>
                                <div className=' w-50px h-50px rounded-circle  card-circle'>

                                </div>
                                <div className='w-50px h-50px rounded-circle  card-circle'>

                                </div>
                                <div className='w-50px h-50px rounded-circle  card-circle'>

                                </div>
                            </div>

                            <div className='d-flex gap-2'>
                                <div className='w-50px h-50px w-sm-90px h-sm-90px  rounded-3  card-circle'>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className='col-12 col-lg-6 border rounded'>
                    <div className='p-3'>
                        <div className='d-flex align-items-center gap-3'>
                            <i className="bi bi-card-image fs-4 "></i>
                            <div className='fs-6'>Choose Background</div>
                        </div>

                        <ul className="nav nav-tabs my-5 border rounded" id="pills-tab" role="tablist">
                            <li className="nav-item " role="presentation">
                                <button className="nav-link active" id="pills-Wallpaper-tab" data-bs-toggle="pill" data-bs-target="#pills-Wallpaper" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Wallpaper</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-image-tab" data-bs-toggle="pill" data-bs-target="#pills-image" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Upload Image</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-gradient-tab" data-bs-toggle="pill" data-bs-target="#pills-gradient" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Gradient</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-color-tab" data-bs-toggle="pill" data-bs-target="#pills-color" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Plain Color</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">


                            {/* tab1 */}
                            <div className="tab-pane fade show active gap-2" id="pills-Wallpaper" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                <div className='d-flex gap-3'>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Industry1
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 2
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 3
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 4
                                    </button>

                                </div>
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    className='py-5 '
                                    navigation
                                    onSwiper={(swiper) => AppLog(swiper)}
                                    onSlideChange={() => AppLog('slide change')}
                                    breakpoints={{
                                        368: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },
                                        478: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        1025: {
                                            slidesPerView: 4.2,
                                            spaceBetween: 10
                                        },
                                        1240: {
                                            slidesPerView: 5,
                                            spaceBetween: 10
                                        },
                                        1440: {
                                            slidesPerView: 5.5,
                                            spaceBetween: 10
                                        }
                                    }}
                                >
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle1.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle2.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle3.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle4.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle5.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle1.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle1.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle1.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">
                                        <img className='w-100 h-100 object-cover' src={toAbsoluteUrl('/media/Dashboard/Rectangle4.png')} alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                </Swiper>

                            </div>

                            {/* tab2 */}
                            <div className="tab-pane fade show  gap-2" id="pills-image" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                <div className="image-drop-zone">
                                    {selectedImage ? (
                                        <img src={selectedImage} alt="Selected" />
                                    ) : (
                                        <>
                                            <i style={{ fontSize: "40px" }} className="bi bi-cloud-arrow-up-fill my-3"></i>
                                            <label htmlFor="file-input" onDragOver={handleDragOver} onDrop={handleDrop}>
                                                Drag and drop or <span className='text-primary text-decoration-underline' onDrop={handleDragOver}>Browse File</span>
                                            </label>
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        id="file-input"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                            </div>


                            {/* tab3 */}
                            <div className="tab-pane fade show  gap-2" id="pills-gradient" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                <div className='d-flex gap-3'>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 1
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 2
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 3
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 4
                                    </button>

                                </div>
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    className='py-5'
                                    navigation
                                    onSwiper={(swiper) => AppLog(swiper)}
                                    onSlideChange={() => AppLog('slide change')}
                                    breakpoints={{
                                        368: {
                                            slidesPerView: 2,
                                            spaceBetween: 10
                                        },
                                        478: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        1024: {
                                            slidesPerView: 5.5,
                                            spaceBetween: 10
                                        },
                                    }}
                                >
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                </Swiper>

                            </div>

                            {/* tab4 */}
                            <div className="tab-pane fade show  gap-2" id="pills-color" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                <div className='d-flex gap-3'>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 1
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 2
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 3
                                    </button>
                                    <button className='rounded-pill border border-secondary px-3 py-2 font-dark1'>
                                        Domain 4
                                    </button>

                                </div>
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    className='py-5'
                                    navigation
                                    onSwiper={(swiper) => AppLog(swiper)}
                                    onSlideChange={() => AppLog('slide change')}
                                    breakpoints={{
                                        478: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            spaceBetween: 10
                                        },
                                        1024: {
                                            slidesPerView: 5.5,
                                            spaceBetween: 10
                                        },
                                    }}
                                >
                                    <SwiperSlide style={{ height: "80px", width: "80px" }} className="col-2 bg-secondary  rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                    <SwiperSlide className="col-2 bg-secondary h-80px rounded-4">

                                    </SwiperSlide>
                                </Swiper>

                            </div>
                        </div>


                        <div className='w-100'>
                            <div onClick={handlelogo} className='py-4 d-flex align-items-center gap-3 border-top border-bottom'>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V18C0 20.2091 1.79086 22 4 22H18C20.2091 22 22 20.2091 22 18V4C22 1.79086 20.2091 0 18 0H4ZM11.4758 16.5252L11.9921 14.9252C12.4419 13.5387 13.5326 12.4481 14.9252 11.9921L16.5252 11.4758C16.9871 11.3268 16.9871 10.6732 16.5252 10.5242L14.9252 10.0079C13.5326 9.5581 12.4419 8.46743 11.9921 7.07482L11.4758 5.47482C11.3268 5.01286 10.6732 5.01287 10.5242 5.47483L10.0079 7.07482C9.5581 8.46743 8.46743 9.5581 7.07482 10.0079L5.47483 10.5242C5.01286 10.6732 5.01286 11.3268 5.47482 11.4758L7.07482 11.9921C8.46743 12.4419 9.5581 13.5326 10.0079 14.9252L10.5242 16.5252C10.6732 16.9871 11.3268 16.9871 11.4758 16.5252Z" fill="#A8AFB7" />
                                </svg>
                                <div style={{ fontSize: "20px" }} className='text-black  fw-bold'>
                                    Add Logo
                                </div>
                            </div>
                            {logo &&
                                <div className="image-drop-zone">
                                    {selectedImage ? (
                                        <img src={selectedImage} alt="Selected" />
                                    ) : (
                                        <>
                                            <i style={{ fontSize: "40px" }} className="bi bi-cloud-arrow-up-fill my-3"></i>
                                            <label htmlFor="file-input" onDragOver={handleDragOver} onDrop={handleDrop}>
                                                Drag and drop or <span className='text-primary text-decoration-underline' onDrop={handleDragOver}>Browse File</span>
                                            </label>
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        id="file-input"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </div>

                            }
                            <div className='py-4 d-flex align-items-center gap-3 border-top border-bottom'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9997 11.3333C19.9997 10.9653 19.701 10.6666 19.333 10.6666H15.9997C15.6317 10.6666 15.333 10.9653 15.333 11.3333C15.333 11.7013 15.6317 12 15.9997 12H18.6663V14C18.6663 14.368 18.965 14.6666 19.333 14.6666C19.701 14.6666 19.9997 14.368 19.9997 14V11.3333Z" fill="#A8AFB7" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.667 19.3333C10.667 19.7013 10.9657 20 11.3337 20H19.3337C19.7017 20 20.0003 19.7013 20.0003 19.3333C20.0003 18.9653 19.7017 18.6666 19.3337 18.6666H11.3337C10.9657 18.6666 10.667 18.9653 10.667 19.3333Z" fill="#A8AFB7" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 4.00004C3.33301 3.63204 3.63167 3.33337 3.99967 3.33337H5.33301C5.70101 3.33337 5.99967 3.63204 5.99967 4.00004V5.33337C5.99967 5.70137 5.70101 6.00004 5.33301 6.00004H3.99967C3.63167 6.00004 3.33301 5.70137 3.33301 5.33337V4.00004ZM0 0.666667V8.66667C0 9.03467 0.298667 9.33333 0.666667 9.33333H8.66667C9.03467 9.33333 9.33333 9.03467 9.33333 8.66667V0.666667C9.33333 0.298667 9.03467 0 8.66667 0H0.666667C0.298667 0 0 0.298667 0 0.666667Z" fill="#A8AFB7" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 14.6667C3.33301 14.2987 3.63167 14 3.99967 14H5.33301C5.70101 14 5.99967 14.2987 5.99967 14.6667V16C5.99967 16.368 5.70101 16.6667 5.33301 16.6667H3.99967C3.63167 16.6667 3.33301 16.368 3.33301 16V14.6667ZM0 19.3333C0 19.7013 0.298667 20 0.666667 20H8.66667C9.03467 20 9.33333 19.7013 9.33333 19.3333V11.3333C9.33333 10.9653 9.03467 10.6666 8.66667 10.6666H0.666667C0.298667 10.6666 0 10.9653 0 11.3333V19.3333Z" fill="#A8AFB7" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16.6667C16.368 16.6667 16.6667 16.368 16.6667 16V14.6667C16.6667 14.2987 16.368 14 16 14H14.6667C14.2987 14 14 14.2987 14 14.6667V16C14 16.368 14.2987 16.6667 14.6667 16.6667H16Z" fill="#A8AFB7" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6667 5.33337C16.6667 5.70137 16.368 6.00004 16 6.00004H14.6667C14.2987 6.00004 14 5.70137 14 5.33337V4.00004C14 3.63204 14.2987 3.33337 14.6667 3.33337H16C16.368 3.33337 16.6667 3.63204 16.6667 4.00004V5.33337ZM20.0003 0.666667C20.0003 0.298667 19.7017 0 19.3337 0H11.3337C10.9657 0 10.667 0.298667 10.667 0.666667V8.66667C10.667 9.03467 10.9657 9.33333 11.3337 9.33333H19.3337C19.7017 9.33333 20.0003 9.03467 20.0003 8.66667V0.666667Z" fill="#A8AFB7" />
                                </svg>

                                <div style={{ fontSize: "20px" }} className='text-black  fw-bold'>
                                    Add QR code
                                </div>
                            </div>
                            <div className='py-4 d-flex align-items-center gap-3 border-top border-bottom'>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V18C0 20.2091 1.79086 22 4 22H18C20.2091 22 22 20.2091 22 18V4C22 1.79086 20.2091 0 18 0H4ZM5.42857 5H16.5714C16.8286 5 17 5.17143 17 5.42857V8C17 8.25714 16.8286 8.42857 16.5714 8.42857H15.7143C15.5857 8.42857 15.4571 8.34286 15.3714 8.25714L14.6429 7.14286H12.2857V14.6857L13.4429 15.8429C13.5286 15.9286 13.5714 16.0143 13.5714 16.1429V16.5714C13.5714 16.8286 13.4 17 13.1429 17H8.85714C8.6 17 8.42857 16.8286 8.42857 16.5714V16.1429C8.42857 16.0143 8.47143 15.9286 8.55714 15.8429L9.71429 14.6857V7.14286H7.31429L6.15714 8.3C6.07143 8.38571 5.98571 8.42857 5.85714 8.42857H5.42857C5.17143 8.42857 5 8.25714 5 8V5.42857C5 5.17143 5.17143 5 5.42857 5Z" fill="#A8AFB7" />
                                </svg>

                                <div style={{ fontSize: "20px" }} className='text-black  fw-bold'>
                                    Add Details
                                </div>
                            </div>
                            <div className='py-4 d-flex align-items-center gap-3 border-top border-bottom'>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H18C20.2091 0 22 1.79086 22 4V18C22 20.2091 20.2091 22 18 22H4C1.79086 22 0 20.2091 0 18V4ZM12.0127 16.1426L10.0833 14.2137C10.658 14.3737 11.2721 14.3844 11.8551 14.2287C11.9123 14.2131 11.9679 14.1932 12.0235 14.1733C12.0447 14.1657 12.0659 14.1581 12.0872 14.1508L13.0459 15.1095C13.6156 15.6804 14.5398 15.6784 15.1111 15.1095C15.6808 14.5371 15.6804 13.6156 15.1106 13.0447L13.807 11.7406L13.0458 10.9799C12.8579 10.792 12.6303 10.672 12.3902 10.6079C11.9035 10.4766 11.364 10.5963 10.9812 10.9792C10.723 11.2369 10.5872 11.5664 10.5641 11.9027C10.4512 11.8535 10.3474 11.7925 10.2629 11.7085L9.35925 10.8048C9.50049 10.4944 9.6923 10.2014 9.94815 9.94619C10.3241 9.56957 10.7786 9.32008 11.2586 9.19233C12.0066 8.99302 12.817 9.09597 13.4992 9.50075C13.7056 9.62281 13.9015 9.7698 14.0789 9.94688L16.1441 12.0121C17.2861 13.1534 17.2836 15.0025 16.1456 16.1425C15.0023 17.2826 13.1536 17.2841 12.0127 16.1426ZM10.9115 8.84473C11.5561 8.62255 12.2537 8.60007 12.9088 8.78113L10.9872 6.85584C9.84534 5.71477 7.99657 5.71477 6.85528 6.85584C5.71445 7.99616 5.71514 9.84608 6.85597 10.9876L8.92175 13.0534C9.09952 13.2305 9.29532 13.377 9.50146 13.4987C10.1843 13.903 10.9938 14.0064 11.7426 13.8066C12.2222 13.6788 12.6762 13.4291 13.0536 13.0532C13.3079 12.7982 13.4993 12.5062 13.6396 12.1967L12.7348 11.2926C12.6461 11.2038 12.5436 11.1384 12.4356 11.0891C12.4136 11.4275 12.2778 11.759 12.0196 12.0192C11.6352 12.4014 11.0958 12.521 10.609 12.3905C10.3699 12.3267 10.1433 12.207 9.95476 12.0198L7.88859 9.95404C7.32039 9.38215 7.31931 8.45997 7.88928 7.88826C8.46001 7.31892 9.38419 7.31892 9.95506 7.88757L10.9115 8.84473Z" fill="#A8AFB7" />
                                </svg>

                                <div style={{ fontSize: "20px" }} className='text-black  fw-bold'>
                                    Add  Links
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default Vrcards