import { Action } from "../actionTypes";

const initState = {
    AllUser: [],
    UserById: [],
    downloaduser: [],
    error: "",
};

const store = (state = initState, action: any) => {
    switch (action.type) {
        case Action.ADMIN_GET_ALLUSER_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_ALLUSER_SUCCESS:
            return {
                ...state,
                AllUser: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_USERBY_ID_INIT:
            return {
                ...state
            }
        case Action.GET_USERBY_ID_SUCCESS:
            return {
                ...state,
                UserById: action?.payload
            }
        case Action.GET_USERBY_ID_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloaduser: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}
export default store;
