// import { API_URL } from "../../config";
// import axios from "axios";
import { AppLog } from '../../_4threal/helpers'
import { generatePopup } from '../../utils/popup'
import {
  getFirestore,
  setDoc,
  getDocs,
  collection,
  doc,
  where,
  query,
  // getDoc,
} from 'firebase/firestore'
import {firestore} from '../../firebase'
import {DocumentData} from 'firebase/firestore' // Import the necessary types from Firestore
import { Action } from '../actionTypes'

export const getAllPlan: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const planCollection = collection(firestore, 'plans')

    getDocs(planCollection)
      .then((querySnapshot) => {
        const plans: DocumentData[] = []

        querySnapshot.forEach((doc: any) => {
          const planData = doc.data()
          const planId = doc.id

          // Add the planId as a property to planData
          const planWithId = {...planData, planId}

          plans.push(planWithId)
        })
        dispatch({
          type: Action.GET_ALL_PLAN_SUCCESS,
          payload: plans,
        })

        resolve(plans)
      })
      .catch((error) => {
        dispatch({
          type: Action.GET_ALL_PLAN_FAIL,
          payload: error,
        })

        reject(error)
      })
  })
}

export const getPlanById: any = (payload: any) => async (dispatch: any) => {
  if (payload) {
    try {
      const q = query(collection(firestore, 'plans'), where('Id', '==', payload))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const plans: any = querySnapshot.docs.map((doc) => doc.data())
        dispatch({
          type: Action.GET_SINGLE_PLAN_SUCCESS,
          payload: plans[0],
        })
      } else {
        throw new Error(`No plans found with status: ${payload}`)
      }
    } catch (error: any) {
      dispatch({
        type: Action.GET_SINGLE_PLAN_FAIL,
        payload: error.message,
      })
    //   generatePopup('error', error.message)
    }
  }
}

export const updatePlan: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {planid, updatedData} = payload
      dispatch({
        type: Action.UPDATE_USER_INIT,
      })

      const db = getFirestore() // Initialize Firestore

      const userDocRef = doc(db, 'plans', planid) // Reference to the user document

      setDoc(userDocRef, updatedData)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          AppLog(`Unsuccessful returned error ${error}`)
        })

      dispatch({
        type: Action.UPDATE_PLAN_SUCCESS,
      })

      generatePopup('success', 'plan update successfully.')
    } catch (error) {
      const typedError = error as Error
      dispatch({
        type: Action.UPDATE_PLAN_FAIL,
        payload: typedError.message || 'Failed to Update plan!',
      })
      reject(error)
    }
  })
}
