export enum Action {
  //Auth
  AUTH_REGISTER_INIT, AUTH_REGISTER_SUCCESS, AUTH_REGISTER_FAIL,
  BETA_REGISTER_INIT, BETA_REGISTER_SUCCESS, BETA_REGISTER_FAIL,
  AUTH_LOGIN_INIT, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAIL,
  PASSWORD_CHANGE_INIT, PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_FAIL,
  GET_LOGINUSER_PROFILE_INIT, GET_LOGINUSER_PROFILE_SUCCESS, GET_LOGINUSER_PROFILE_FAIL,

  //Blobs
  CREATE_BLOB_INIT, CREATE_BLOB_SUCCESS, CREATE_BLOB_FAIL, CREATE_BLOB_PROGRESS,
  GET_BLOB_URL_INIT, GET_BLOB_URL_SUCCESS, GET_BLOB_URL_FAIL,
  //Assets
  ON_CHANGE_SLIDER, ON_CHANGE_SCALE, ON_CHANGE_LOGO_TRANSFORM,
  GET_ASSET_URL_LIST_INIT, GET_ASSET_URL_LIST_SUCCESS, GET_ASSET_URL_LIST_FAIL,

  GET_ALL_ASSETS_INIT, GET_ALL_ASSETS_SUCCESS, GET_ALL_ASSETS_FAIL,
  POST_CREATE_ASSET_INIT, POST_CREATE_ASSET_SUCCESS, POST_CREATE_ASSET_FAIL,
  POST_UPDATE_ASSET_INIT, POST_UPDATE_ASSET_SUCCESS, POST_UPDATE_ASSET_FAIL,

  //Cards
  GET_ONE_CARD_INIT, GET_ONE_CARD_SUCCESS, GET_ONE_CARD_FAIL,
  GET_ALL_CARD_INIT, GET_ALL_CARD_SUCCESS, GET_ALL_CARD_FAIL,
  GET_ONE_MARKER_INIT, GET_ONE_MARKER_SUCCESS, GET_ONE_MARKER_FAIL,
  GET_ALL_MARKER_INIT, GET_ALL_MARKER_SUCCESS, GET_ALL_MARKER_FAIL,

  POST_CREATE_CARD_INIT, POST_CREATE_CARD_SUCCESS, POST_CREATE_CARD_FAIL,
  POST_UPDATE_CARD_INIT, POST_UPDATE_CARD_SUCCESS, POST_UPDATE_CARD_FAIL,
  POST_CREATE_MARKER_INIT, POST_CREATE_MARKER_SUCCESS, POST_CREATE_MARKER_FAIL,
  POST_UPDATE_MARKER_INIT, POST_UPDATE_MARKER_SUCCESS, POST_UPDATE_MARKER_FAIL,
  GET_ALL_ORDERS_INIT, GET_ALL_ORDERS_SUCCESS, GET_ALL_ORDERS_FAIL,
  GET_ALL_DELETE_MARKER_INIT, GET_ALL_DELETE_MARKER_SUCCESS, GET_ALL_DELETE_MARKER_FAIL,
  GET_ALL_DELETE_CARD_INIT, GET_ALL_DELETE_CARD_SUCCESS, GET_ALL_DELETE_CARD_FAIL,
  GET_ACTIVE_CARD_INIT, GET_ACTIVE_CARD_SUCCESS, GET_ACTIVE_CARD_FAIL,
  CARD_DELETE_INIT, CARD_DELETE_SUCCESS, CARD_DELETE_FAIL,
  MARKER_DELETE_INIT, MARKER_DELETE_SUCCESS, MARKER_DELETE_FAIL,
  CARD_RESTORE_INIT, CARD_RESTORE_SUCCESS, CARD_RESTORE_FAIL,
  CARD_STATUS_CHANGE_INIT, CARD_STATUS_CHANGE_SUCCESS, CARD_STATUS_CHANGE_FAIL,
  CARD_UPDATE_INIT, CARD_UPDATE_SUCCESS, CARD_UPDATE_FAIL,

  //Analytics
  GET_CARD_ANALYTICS_INIT, GET_CARD_ANALYTICS_SUCCESS, GET_CARD_ANALYTICS_FAIL,
  GET_TODAY_ANALYTICS_INIT, GET_TODAY_ANALYTICS_SUCCESS, GET_TODAY_ANALYTICS_FAIL,
  //Admin
  ADMIN_GET_ALLUSER_INIT, ADMIN_GET_ALLUSER_SUCCESS, ADMIN_GET_ALLUSER_FAIL,
  ADMIN_GET_ALLUSER_DOWNLOAD_INIT, ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS, ADMIN_GET_ALLUSER_DOWNLOAD_FAIL,
  GET_USERBY_ID_INIT, GET_USERBY_ID_SUCCESS, GET_USERBY_ID_FAIL,
  UPDATE_USER_INIT, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL,
  DELETE_USER_INIT, DELETE_USER_SUCCESS, DELETE_USER_FAIL,
  ADD_USER_INIT, ADD_USER_SUCCESS, ADD_USER_FAIL,
  //Plan and Payment
  GET_ALL_PLAN_INIT, GET_ALL_PLAN_SUCCESS, GET_ALL_PLAN_FAIL,
  GET_SINGLE_PLAN_INIT, GET_SINGLE_PLAN_SUCCESS, GET_SINGLE_PLAN_FAIL,
  UPDATE_PLAN_INIT, UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAIL,
  AUTH_STATE_CHANGED,
  GET_PAYMENT_STATUS_INIT, GET_PAYMENT_STATUS_SUCCESS, GET_PAYMENT_STATUS_FAIL,
  USER_PAYMENT_INIT, USER_PAYMENT_SUCCESS, USER_PAYMENT_FAIL,

  //Files or Misc
  GET_IMAGES_INIT, GET_IMAGES_SUCCESS, GET_IMAGES_FAIL,
  GET_VIDEOS_INIT, GET_VIDEOS_SUCCESS, GET_VIDEOS_FAIL,
  GET_BC_INIT, GET_BC_SUCCESS, GET_BC_FAIL,
  GET_PC_INIT, GET_PC_SUCCESS, GET_PC_FAIL,
}


