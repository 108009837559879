import { Action } from "../actionTypes";

const initState = {
    UserCard: [],
    UserMarker: [],
    UserOrders: [],
    card: {},
    marker: {},
    error: "",
    DeletedCard: [],
    DeletedMarker: [],
    ActiveCard: [],
    analytics: {},
    todayanalytics: {},
    UserAsset: {},
    AllAssets: [],
};
const store = (state = initState, action: any) => {
    switch (action.type) {
        case Action.GET_ALL_CARD_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_CARD_SUCCESS:
            return {
                ...state,
                UserCard: action?.payload
            }
        case Action.GET_ALL_CARD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ALL_MARKER_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_MARKER_SUCCESS:
            return {
                ...state,
                UserMarker: action?.payload
            }
        case Action.GET_ALL_MARKER_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ALL_ORDERS_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                UserOrders: action?.payload
            }
        case Action.GET_ALL_ORDERS_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ALL_DELETE_CARD_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_DELETE_CARD_SUCCESS:
            return {
                ...state,
                DeletedCard: action?.payload
            }
        case Action.GET_ALL_DELETE_CARD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ALL_DELETE_MARKER_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_DELETE_MARKER_SUCCESS:
            return {
                ...state,
                DeletedMarker: action?.payload
            }
        case Action.GET_ALL_DELETE_MARKER_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ACTIVE_CARD_INIT:
            return {
                ...state
            }
        case Action.GET_ACTIVE_CARD_SUCCESS:
            return {
                ...state,
                ActiveCard: action?.payload
            }
        case Action.GET_ACTIVE_CARD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_ONE_CARD_INIT:
            return {
                ...state
            }
        case Action.GET_ONE_CARD_SUCCESS:
            return {
                ...state,
                card: action?.payload
            }
        case Action.GET_ONE_CARD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
            case Action.GET_ONE_MARKER_INIT:
                return {
                    ...state
                }
            case Action.GET_ONE_MARKER_SUCCESS:
                return {
                    ...state,
                    marker: action?.payload
                }
            case Action.GET_ONE_MARKER_FAIL:
                return {
                    ...state,
                    error: action?.payload
                }
        case Action.GET_CARD_ANALYTICS_INIT:
            return {
                ...state
            }
        case Action.GET_CARD_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: action?.payload
            }
        case Action.GET_CARD_ANALYTICS_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_TODAY_ANALYTICS_INIT:
            return {
                ...state
            }
        case Action.GET_TODAY_ANALYTICS_SUCCESS:
            return {
                ...state,
                todayanalytics: action?.payload
            }
        case Action.GET_TODAY_ANALYTICS_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        // Asset Reducers (consider moving these to their own tsx file)

        case Action.GET_ALL_ASSETS_INIT:
            return {
                ...state
            }
        case Action.GET_ALL_ASSETS_SUCCESS:
            return {
                ...state,
                AllAssets: action?.payload
            }
        case Action.GET_ALL_ASSETS_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        case Action.POST_CREATE_ASSET_INIT:
            return {
                ...state
            }
        case Action.POST_CREATE_ASSET_SUCCESS:
            return {
                ...state,
                UserAsset: action?.payload
            }
        case Action.POST_CREATE_ASSET_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        case Action.POST_UPDATE_ASSET_INIT:
            return {
                ...state
            }
        case Action.POST_UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                UserAsset: action?.payload
            }
        case Action.POST_UPDATE_ASSET_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        default:
            return state;
    }
}

export default store;
