/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import { ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan } from '../partials'
import { PageDataProvider } from './core'
import { reInitMenu } from '../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getCard } from '../../redux/action/card'
// import { ToolbarWrapper } from './components/toolbar' //omitted due to eslint warning
// import { ScrollTop } from './components/scroll-top' //omitted due to eslint warning
// import {RightToolbar} from '../partials/layout/RightToolbar' //omitted due to eslint warning

const MasterLayout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const Allcard = useSelector((state: any) => state.Card?.UserCard)
  const userId = useSelector((state: any) => state._id)

  useEffect(() => {
    handleGetAllCard()
  }, [userId])

  const handleGetAllCard = () => {
    if (userId) {
      dispatch(getCard({ userId }))
    }
  }

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            {Allcard ? <Sidebar /> : ''}
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export { MasterLayout }