// import React from 'react'

function formatDateToDDMMYY(date) {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Adding 1 to the month since it's zero-based
  const year = date.getFullYear().toString().slice(2, 4) // Extract the last two digits of the year

  return `${day}-${month}-${year}`
}

const CheckActivePlan = (subscriptions) => {
  // Get the current date as "dd-mm-yy" format
  const currentDate = formatDateToDDMMYY(new Date())
  // Find the active plan
  if(subscriptions){
  const activePlan = subscriptions?.find((subscription: any) => {
    // const startDate = new Date(subscription.subscription.StartDate.seconds * 1000);
    const endDate = new Date(subscription.end_date.seconds * 1000)
    // Format the dates as "dd-mm-yy"
    // const formattedStartDate = formatDateToDDMMYY(startDate);
    const formattedEndDate = formatDateToDDMMYY(endDate)
    // Check if the current date is earlier than the end date
    if (new Date(currentDate) <= new Date(formattedEndDate)) {
      return subscription
    }
    return null
  })

  return activePlan
}else{
    return null;
}
}

export default CheckActivePlan
