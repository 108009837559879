import {Euler, EventDispatcher, MathUtils, Quaternion, Vector3} from 'three';

/**
 * W3C Device Orientation control (http://w3c.github.io/deviceorientation/spec-source-orientation.html)
 */

var DeviceOrientationControls = function(object, cameraPosition = 3, targetPosition = null, minPitch = 45, maxPitch = 135, minYaw = -45, maxYaw = 45) {

  var scope = this;
  var changeEvent = {
    type: 'change'
  };
  var EPS = 0.000001;
  this.target = targetPosition || new Vector3();
  //this.cameraDistance = cameraPosition;
  this.object = object;
  this.object.rotation.reorder('YXZ');

  this.enabled = true;

  this.deviceOrientation = {};
  this.screenOrientation = 0;

  this.alphaOffset = 0; // radians

  var minPitchRadians = MathUtils.degToRad(minPitch);
  var maxPitchRadians = MathUtils.degToRad(maxPitch);
  var minYawRadians = MathUtils.degToRad(minYaw);
  var maxYawRadians = MathUtils.degToRad(maxYaw);

  var onDeviceOrientationChangeEvent = function(event) {

    scope.deviceOrientation = event;

  };

  var onScreenOrientationChangeEvent = function() {

    scope.screenOrientation = window.orientation || 0;

  };

  // The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''
  var setObjectQuaternion = function() {
    var zee = new Vector3(0, 0, 1);
    var euler = new Euler();
    var q0 = new Quaternion();
    var q1 = new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

    return function(quaternion, alpha, beta, gamma, orient) {
        // Apply yaw constraints
       // alpha = Math.max(minYawRadians, Math.min(maxYawRadians, alpha));

        // Apply pitch constraints
        beta = Math.max(minPitchRadians, Math.min(maxPitchRadians, beta));

        euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
        quaternion.setFromEuler(euler); // orient the device
        quaternion.multiply(q1); // camera looks out the back of the device, not the top
        quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
        euler.setFromQuaternion(quaternion, 'YXZ'); // Convert to Euler to check/adjust yaw
        euler.y = Math.max(minYawRadians, Math.min(maxYawRadians, euler.y)); // Adjust yaw within constraints
        quaternion.setFromEuler(euler);
    };
}();

  this.connect = function() {

    onScreenOrientationChangeEvent(); // run once on load
    // iOS 13+
    if (window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function') {

      window.DeviceOrientationEvent.requestPermission()
      .then(function(response) {
        if (response === 'granted') {
          window.addEventListener('orientationchange', onScreenOrientationChangeEvent, false);
          window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent, false);
        }
      })
      .catch (function(error) {
        console.error('THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error);
      });

    } else {
      window.addEventListener('orientationchange', onScreenOrientationChangeEvent, false);
      window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent, false);
    }

    scope.enabled = true;

  };

  this.disconnect = function() {

    window.removeEventListener('orientationchange', onScreenOrientationChangeEvent, false);
    window.removeEventListener('deviceorientation', onDeviceOrientationChangeEvent, false);

    scope.enabled = false;

  };

  this.update = (function() {

    var lastQuaternion = new Quaternion();

    return function() {

      if (scope.enabled === false) return;

      var device = scope.deviceOrientation;

      if (device) {

        var alpha = device.alpha ? MathUtils.degToRad(device.alpha) + scope.alphaOffset : 0; // Z
        var beta = device.beta ? MathUtils.degToRad(device.beta) : 1.5; // X'
        var gamma = device.gamma ? MathUtils.degToRad(device.gamma) : 0; // Y''
        var orient = scope.screenOrientation ? MathUtils.degToRad(scope.screenOrientation) : 0; // O
        setObjectQuaternion(scope.object.quaternion, alpha, beta, gamma, orient);
        var distanceToTarget = cameraPosition; // Adjust this value as needed
        scope.object.position.copy(scope.target).add(new Vector3(0, 0, distanceToTarget).applyQuaternion(scope.object.quaternion));

        // Point the camera to the target
        scope.object.lookAt(scope.target);

        if (8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS) {
          lastQuaternion.copy(scope.object.quaternion);
          scope.dispatchEvent(changeEvent);
        }

      }

    };


  })();

  this.dispose = function() {

    scope.disconnect();

  };

  this.connect();

};

DeviceOrientationControls.prototype = Object.create(EventDispatcher.prototype);
DeviceOrientationControls.prototype.constructor = DeviceOrientationControls;

export {
  DeviceOrientationControls
};
