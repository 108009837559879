// import React, { useState } from 'react' //omitted due to eslint warnings
// import Planmodel from '../../../../app/pages/Plandetail/model/Planmodel' //omitted due to eslint warnings

export default function PaymentWarning(props: any) {
  // const [model, setModel] = useState(false)
  // const [currentState, setCurrentState] = useState<'monthly' | 'yearly'>('monthly')
  // const handleClose = () => {
  //   setModel(false)
  // }
  return (
    <>

      <div
        style={{ backgroundColor: '#f5f5f5', left: '80%', bottom: '5%' }}
        className='position-absolute w-200px  mx-3  py-5 px-3 rounded d-none  d-md-block'
      >
        <div className='w-100 text-center'>
          <i className='bi bi-arrow-up-circle text-center fs-1 font-dark1'></i>
        </div>
        <h5 className='text-dark my-2 text-center'>Upgrade to publish</h5>
        <p className='text-center' style={{ color: '#767676' }}>
          Upgrade to make your card live and grow your business faster
        </p>
        <div className='px-3'>
          <button
            className='bg-blue border-0 p-2 rounded w-100 text-light'
            onClick={() => {
              // setModel(true) //this is unused, omitting for now
            }}
          >
            View plans
          </button>
        </div>
      </div>

      {/* removed due to eslint warnings */}
      {/* {model && (
        <Planmodel
          setCurrentState={setCurrentState}
          handleClose={handleClose}
          model={model}
          currentState={currentState}
          setModel={setModel}
        />
      )} */}
    </>
  )
}
