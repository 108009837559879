export default function timeSince(timestamp) {
    const now = new Date().getTime()
    const secondsPast = (now - timestamp) / 1000

    if (secondsPast < 60) {
        // Less than a minute
        return `${Math.round(secondsPast)} seconds ago`
    } else if (secondsPast < 3600) {
        // Less than an hour
        return `${Math.round(secondsPast / 60)} minutes ago`
    } else if (secondsPast <= 86400) {
        // Less than a day
        return `${Math.round(secondsPast / 3600)} hours ago`
    } else {
        // More than a day
        return `${Math.round(secondsPast / 86400)} days ago`
    }
}