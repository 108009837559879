import { useDispatch } from 'react-redux';
import * as Yup from 'yup'
import { Field, Formik } from 'formik';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import { passwordChange } from '../../../redux/action/auth';
import { generatePopup } from '../../../utils/popup';
import { useNavigate } from 'react-router-dom';

const Changepassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultValues = {
    email: '',
    oldpassword: '',
    password: '',
    changepassword: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    oldpassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    changepassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  })

  const handleFormSubmit = async (values: any, { resetForm }) => {
    let obj = {
      email: values?.email,
      oldPassword: values?.oldpassword,
      newPassword: values?.password,
      // changepassword: values?.changepassword,
    }
    resetForm({ values: defaultValues });
    dispatch(passwordChange(obj)).then((res: any) => {
      if (res?.status === 200) {
        generatePopup("success", "password updated successfully.");
        localStorage.setItem('authToken', res?.data?.token.replace('Bearer ', ''));
      } else if (res?.status === 404) {
        generatePopup("error", "User is not exist.");
      }
    })
  }

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <form
              id='kt_modal_add_user_form'
              className='form'
              encType="multipart/form-data"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0'>
                  <div className='modal-header mb-7'>
                    <h2 className='fw-bolder'>Change password</h2>
                  </div>
                </ul>

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Email</label>

                  <Field
                    placeholder='Email'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {Array.isArray(formik.errors.email)
                            ? (formik.errors.email as string[]).join(", ")
                            : (formik.errors.email as string)
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Old Password</label>
                  <Field
                    type='password'
                    placeholder='Old Password'
                    autoComplete='off'
                    {...formik.getFieldProps('oldpassword')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid': formik.touched.oldpassword && formik.errors.oldpassword,
                      },
                      {
                        'is-valid': formik.touched.oldpassword && !formik.errors.oldpassword,
                      }
                    )}
                  />
                  {formik.touched.oldpassword && formik.errors.oldpassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.oldpassword}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-8' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
                    <div className='position-relative mb-3'>
                      <Field
                        type='password'
                        placeholder='New Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Confirm new Password</label>
                  <Field
                    type='password'
                    placeholder='Password new confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                      },
                      {
                        'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                      }
                    )}
                  />
                  {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.changepassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='text-center pt-15'>
                <Button
                  type='reset'
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                  disabled={formik.isSubmitting}
                  onClick={() => navigate("/dashboard")}
                >
                  cancel
                </Button>
                <Button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  )
}

export default Changepassword