import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_4threal/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_4threal/layout/core'
import { MasterInit } from '../_4threal/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_4threal/partials'
import './../aframe-react.d.ts';
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Toaster
                position="top-center"
                reverseOrder={false}
              />
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
