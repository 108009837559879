import { Action } from "../actionTypes";


const initState = {
    loading: false,
    user: null,
    authProfile: [],
    error: "",
    paymentStatus: []
};

const store = (state = initState, action) => {
    switch (action.type) {
        case Action.AUTH_REGISTER_INIT:
            return {
                ...state
            }
        case Action.AUTH_REGISTER_SUCCESS:
            return {
                ...state,
                error: ""
            }
        case Action.AUTH_REGISTER_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        case Action.AUTH_LOGIN_INIT:
            return {
                ...state
            }
        case Action.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                error: ""
            }
        case Action.AUTH_LOGIN_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_LOGINUSER_PROFILE_INIT:
            return {
                ...state
            }
        case Action.GET_LOGINUSER_PROFILE_SUCCESS:
            return {
                ...state,
                authProfile: action?.payload,
                error: ""
            }
        case Action.GET_LOGINUSER_PROFILE_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_PAYMENT_STATUS_INIT:
            return {
                ...state
            }
        case Action.GET_PAYMENT_STATUS_SUCCESS:
            return {
                ...state,
                paymentStatus: action?.payload,
                error: ""
            }
        case Action.GET_PAYMENT_STATUS_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.AUTH_STATE_CHANGED:
            return {
                ...state,
                authProfile: action.payload
            }
        default:
            return state;
    }
}
export default store;